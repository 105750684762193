import "./Projects.css";
import balanceOne from "../../assets/project_screens/balance_app_1.png";
import balanceTwo from "../../assets/project_screens/balance_app_2.png";
import balanceThree from "../../assets/project_screens/balance_app_4.png";
import hiredOne from "../../assets/project_screens/hired_app_1.png";
import hiredTwo from "../../assets/project_screens/hired_app_2.png";
import hiredThree from "../../assets/project_screens/hired_app_3.png";
import hedonisticOne from "../../assets/project_screens/hedonistic_app_1.png";
import hedonisticTwo from "../../assets/project_screens/hedonistic_app_2.png";
import hedonisticThree from "../../assets/project_screens/hedonistic_app_3.png";
import { Link } from "react-router-dom";

export default function Projects() {
  return (
    <div className="projects-container">
      <h3>Projects</h3>
      <div className="project-item">
        <div className="project-images">
          <img className="mobile" src={balanceOne} alt="" />
          <img className="mobile" src={balanceTwo} alt="" />
          <img className="mobile" src={balanceThree} alt="" />
        </div>
        <div className="project-description">
          <h4>Balance</h4>
          <p>
            "Balance" is a comprehensive and user-friendly application that
            brings the transformative practices of yoga and meditation directly
            to your fingertips. Whether you're a beginner or an experienced
            practitioner, Balance offers a wide range of classes, guided
            meditations, and resources to suit your needs and support you on
            your journey towards self-discovery.
          </p>
          <div className="skills">
            <div className="skill language">
              <p>JavaScript</p>
            </div>
            <div className="skill language">
              <p>HTML5</p>
            </div>
            <div className="skill language">
              <p>CSS3</p>
            </div>
            <div className="skill library-framework">
              <p>React</p>
            </div>
            <div className="skill library-framework">
              <p>Node.js</p>
            </div>
            <div className="skill library-framework">
              <p>Express</p>
            </div>
            <div className="skill library-framework">
              <p>Mongoose</p>
            </div>
            <div className="skill database">
              <p>MongoDB</p>
            </div>
            <div className="skill other">
              <p>Token-Based Authentication</p>
            </div>
            <div className="skill other">
              <p>MVC Architecture</p>
            </div>
            <div className="skill other">
              <p>RESTful APIs</p>
            </div>
            <div className="skill design">
              <p>Wireframe</p>
            </div>
            <div className="skill design">
              <p>Prototype</p>
            </div>
            <div className="skill ux">
              <p>Mockup</p>
            </div>
            <div className="skill ux">
              <p>User Flow</p>
            </div>
            <div className="skill design">
              <p>Figma</p>
            </div>
            <div className="skill ux">
              <p>User Research</p>
            </div>
            <div className="skill ux">
              <p>Usability testing</p>
            </div>
            <div className="skill ux">
              <p>Agile</p>
            </div>
            <div className="skill other">
              <p>Application development</p>
            </div>
            <div className="skill ux">
              <p>Information Architecture</p>
            </div>
          </div>
        </div>
      </div>
      <div className="project-item">
        <div className="project-images">
          <img className="desktop" src={hedonisticOne} alt="" />
          <img className="mobile" src={hedonisticTwo} alt="" />
          <img className="mobile" src={hedonisticThree} alt="" />
        </div>
        <div className="project-description">
          <h4>Hedonistic</h4>
          <p>
            Are you tired of scouring the internet for recipe ideas, only to end
            up with a cluttered collection of bookmarks and torn-out magazine
            pages? Look no further! Introducing Hedonistic, the ultimate recipe
            database app that brings a world of culinary delights right to your
            fingertips.
          </p>
          <div className="skills">
            <div className="skill language">
              <p>JavaScript</p>
            </div>
            <div className="skill language">
              <p>HTML5</p>
            </div>
            <div className="skill language">
              <p>CSS3</p>
            </div>
            <div className="skill library-framework">
              <p>Node.js</p>
            </div>
            <div className="skill library-framework">
              <p>Express</p>
            </div>
            <div className="skill library-framework">
              <p>Mongoose</p>
            </div>
            <div className="skill database">
              <p>MongoDB</p>
            </div>
            <div className="skill other">
              <p>MVC Architecture</p>
            </div>
            <div className="skill other">
              <p>RESTful APIs</p>
            </div>
            <div className="skill design">
              <p>Wireframe</p>
            </div>
            <div className="skill design">
              <p>Prototype</p>
            </div>
            <div className="skill ux">
              <p>Mockup</p>
            </div>
            <div className="skill ux">
              <p>User Flow</p>
            </div>
            <div className="skill design">
              <p>Figma</p>
            </div>
            <div className="skill ux">
              <p>Agile</p>
            </div>
            <div className="skill other">
              <p>Application development</p>
            </div>
          </div>
        </div>
      </div>
      <div className="project-item">
        <div className="project-images">
          <img className="mobile" src={hiredOne} alt="" />
          <img className="mobile" src={hiredTwo} alt="" />
          <img className="mobile" src={hiredThree} alt="" />
        </div>
        <div className="project-description">
          <h4>Hired</h4>
          In today's fast-paced and dynamic work environments, staying organized
          and on top of your tasks is crucial. Hired is the ultimate job
          tracking application designed to help you efficiently manage your job
          hunting experience.
          <div className="skills">
            <div className="skill language">
              <p>JavaScript</p>
            </div>
            <div className="skill language">
              <p>HTML5</p>
            </div>
            <div className="skill language">
              <p>CSS3</p>
            </div>
            <div className="skill library-framework">
              <p>Node.js</p>
            </div>
            <div className="skill library-framework">
              <p>Express</p>
            </div>
            <div className="skill library-framework">
              <p>Mongoose</p>
            </div>
            <div className="skill database">
              <p>MongoDB</p>
            </div>
            <div className="skill other">
              <p>Token-Based Authentication</p>
            </div>
            <div className="skill other">
              <p>MVC Architecture</p>
            </div>
            <div className="skill other">
              <p>RESTful APIs</p>
            </div>
            <div className="skill ux">
              <p>Mockup</p>
            </div>
            <div className="skill ux">
              <p>User Flow</p>
            </div>
            <div className="skill design">
              <p>Figma</p>
            </div>
            <div className="skill other">
              <p>Application development</p>
            </div>
          </div>
        </div>
      </div>
      <div className="see-more">
        <Link to="/projects">
          {" "}
          &gt; &gt; &gt; VIEW PROJECTS &lt; &lt; &lt;{" "}
        </Link>
      </div>
    </div>
  );
}
