import UX from "../UX/UX";
import Projects from "../Projects/Projects";
import "./HomePageProjects.css";

export default function HomePageProjects() {
  return (
    <div className="home-page-projects">
      <Projects />
      <UX />
    </div>
  );
}
