import './App.css';
import 'animate.css';
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage"
import ProjectPage from "./pages/ProjectPage/ProjectPage"

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/projects" element={<ProjectPage />} />
      </Routes>
    </div>
  );
}

export default App;
