import "./ProjectPage.css";
import Links from "../../components/Links/Links";
import ProjectGallery from "../../components/ProjectGallery/ProjectGallery";

export default function ProjectPage() {
  return (
    <>
      <div className="container">
        <img className="home_image" src="graf-4.avif" alt="" />
        <img className="page_2_img" src="graf-2.avif" alt="" />
        <img className="page_3_img" src="graf-3.avif" alt="" />
        <img className="home_image" src="graf-4.avif" alt="" />
        <img className="page_2_img desktop-hide" src="graf-2.avif" alt="" />
        <img className="page_3_img desktop-hide" src="graf-3.avif" alt="" />
        <img className="page_2_img desktop-hide" src="graf-1.avif" alt="" />
        <img
          className="page_3_img desktop-hide med-hide"
          src="graf-4.avif"
          alt=""
        />
        <img
          className="page_2_img desktop-hide med-hide"
          src="graf-2.avif"
          alt=""
        />
        <img
          className="page_3_img desktop-hide med-hide"
          src="graf-3.avif"
          alt=""
        />
        <img
          className="page_2_img desktop-hide med-hide med-hide-2"
          src="graf-1.avif"
          alt=""
        />
        <img
          className="page_3_img desktop-hide med-hide med-hide-2"
          src="graf-4.avif"
          alt=""
        />
        <div className="splash_text animate__animated animate__fadeInLeft">
          Projects
        </div>
        <Links />
        <ProjectGallery />
        <div className="running_animation">
          <div class="line">
            <div className="dot dot1"></div>
            <div className="dot dot2"></div>
            <div className="dot dot3"></div>
            <div className="dot dot4"></div>
            <div classNane="dot dot5"></div>
          </div>
        </div>
        <div className="running_animation">
          <div class="line-2">
            <div className="dot2 dot21"></div>
            <div className="dot2 dot22"></div>
            <div className="dot2 dot23"></div>
            <div className="dot2 dot24"></div>
            <div classNane="dot2 dot25"></div>
            <div className="dot2 dot26"></div>
            <div className="dot2 dot27"></div>
            <div classNane="dot2 dot28"></div>
          </div>
        </div>
      </div>
    </>
  );
}
