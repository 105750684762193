import "./Links.css";
import { Link } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faMugSaucer } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

library.add(
  faGithub,
  faLinkedin,
  faEnvelope,
  faInstagram,
  faMugSaucer,
  faArrowLeft
);

export default function Links() {
  return (
    <div className="links">
      <Link className="link" to="/">
        <FontAwesomeIcon className="icon" icon={faArrowLeft} />
      </Link>
      <a
        className="link"
        href="https://linkedin.com/in/anastasiiaasti"
        target="_blank"
      >
        <FontAwesomeIcon className="icon" icon={faLinkedin} />
      </a>

      <a
        className="link"
        href="https://github.com/AnastasiiaAsti"
        target="_blank"
      >
        <FontAwesomeIcon className="icon" icon={faGithub} />
      </a>

      <a
        className="link"
        href="mailto:asti@asticodes.dev"
        target="_blank"
        rel="noreferrer"
        title="Send Me an Email"
        aria-label="Send Me an Email"
      >
        <FontAwesomeIcon className="icon" icon={faEnvelope} />
      </a>

      <a
        className="link"
        href="https://www.instagram.com/asticodes/?hl=en"
        target="_blank"
      >
        <FontAwesomeIcon className="icon" icon={faInstagram} />
      </a>

      <a
        className="link"
        href="https://www.buymeacoffee.com/astii"
        target="_blank"
      >
        <FontAwesomeIcon className="icon" icon={faMugSaucer} />
      </a>
    </div>
  );
}
