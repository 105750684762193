import balanceUx from "../assets/project_screens/balance_ux_2.avif"
import balanceUx2 from "../assets/project_screens/ux-1.avif"
import balanceUx3 from "../assets/project_screens/ux-2.avif"
import balanceUx4 from "../assets/project_screens/ux-3.avif"
// import balanceOne from "../assets/project_screens/balance_app_1.png"
// import balanceTwo from "../assets/project_screens/balance_app_2.png"
// import balanceThree from "../assets/project_screens/balance_app_3.png"
// import balanceFour from "../assets/project_screens/balance_app_4.png"
import gwenOne from "../assets/project_screens/gwen_1.png"
import gwenTwo from "../assets/project_screens/gwen_2.png"
import gwenThree from "../assets/project_screens/gwen_3.png"
import dadOne from "../assets/project_screens/dad_1.png"
import hedonistOne from "../assets/project_screens/hedonistic_app_4.png"
import hedonistTwo from "../assets/project_screens/hedonistic_app_2.png"
import hedonistThree from "../assets/project_screens/hedonistic_app_3.png"
import hedonistFour from "../assets/project_screens/hedonistic_app_5.png"
import flightOne from "../assets/project_screens/flight_app_1.png"
import flightTwo from "../assets/project_screens/flight_app_2.png"
import flightThree from "../assets/project_screens/flight_app_3.png"
import flightFour from "../assets/project_screens/flight_app_4.png"
import artOne from "../assets/project_screens/artwindow_1.png"
import artTwo from "../assets/project_screens/artwindow_2.png"
import artThree from "../assets/project_screens/artwindow_3.png"
import artFour from "../assets/project_screens/artwindow_4.png"
import shmetflixOne from "../assets/project_screens/shmetflix_1.png"
import shmetflixTwo from "../assets/project_screens/shmetflix_2.png"
import ecomOne from "../assets/project_screens/ecommerce_1.png"
import ecomTwo from "../assets/project_screens/ecommerce_2.png"
import ecomThree from "../assets/project_screens/ecommerce_3.png"
import ecomFour from "../assets/project_screens/ecommerce_4.png"
// import golobeOne from "../assets/project_screens/golobe_1.png"
// import golobeTwo from "../assets/project_screens/golobe_2.png"
// import golobeThree from "../assets/project_screens/golobe_3.png"
// import golobeFour from "../assets/project_screens/golobe_4.png"
import organickOne from "../assets/project_screens/organick_1.png"
import organickTwo from "../assets/project_screens/organick_2.png"
import organickThree from "../assets/project_screens/organick_3.png"
import organickFour from "../assets/project_screens/organick_4.png"
import liveOne from "../assets/project_screens/live_1.png"
import liveTwo from "../assets/project_screens/live_2.png"
import liveThree from "../assets/project_screens/live_3.png"
import liveFour from "../assets/project_screens/live_4.png"
// import podcastOne from "../assets/project_screens/podcast_1.png"
// import podcastTwo from "../assets/project_screens/podcast_2.png"
// import podcastThree from "../assets/project_screens/podcast_3.png"
// import podcastFour from "../assets/project_screens/podcast_4.png"
import hiredOne from "../assets/project_screens/hired_app_1.png"
import hiredTwo from "../assets/project_screens/hired_app_2.png"
import hiredThree from "../assets/project_screens/hired_app_3.png"
import memoryOne from "../assets/project_screens//memory_1.png"
import memoryTwo from "../assets/project_screens/memory_2.png"
import djastiOne from "../assets/project_screens/djasti_1.png"
import djastiTwo from "../assets/project_screens/djasti_2.png"
import djastiThree from "../assets/project_screens/djasti_3.png"
import djastiFour from "../assets/project_screens/djasti_4.png"
import datepal from "../assets/project_screens/date_pal.png"
import portfolioOne from "../assets/project_screens/python_portfolio_1.png"
import portfolioTwo from "../assets/project_screens/python_portfolio_2.png"
import portfolioThree from "../assets/project_screens/python_portfolio_3.png"
import portfolioFour from "../assets/project_screens/python_portfolio_4.png"
import spaceOne from "../assets/project_screens/spacelab_1.png"
import spaceeTwo from "../assets/project_screens/spacelab_2.png"
import spaceThree from "../assets/project_screens/spacelab_3.png"
import spaceFour from "../assets/project_screens/spacelab_4.png"
import femiOne from "../assets/project_screens/femispace_1.png"
import femiTwo from "../assets/project_screens/femispace_2.png"
import femiThree from "../assets/project_screens/femispace_3.png"







const projects = [
    {
    id: 1,
    title: "DatePal",
    img: [datepal],
    details: "Don't know how to surprise your date? Out of ideas? Need a boost of imagination? Need a fun challenge? Try DatePal. Press a button and enjoy your custom day plan",
    ux: ["Information Architecture", "Agile", "Usability testing", "User Research", "User Flow", "Mockup",],
    design: ['Prototype', 'wireframe', 'figma', 'Accessibility', "mobile-first design"],
    language: ['JavaScript', 'HTML5', 'CSS3'],
    library: ['React'],
    database: [],
    other: [],
    repoLink: "https://github.com/AnastasiiaAsti/date-pal",
    liveLink: "https://date-pal.netlify.app",
    titleGitHub: "DatePal GitHub Link",
    titleLive: "DatePal Live Link",
    category: "frontend",
    },
    {
        id: 2,
        title: "Hedonistic",
        img: [hedonistOne, hedonistTwo, hedonistThree, hedonistFour],
        details: "Are you tired of scouring the internet for recipe ideas, only to end up with a cluttered collection of bookmarks and torn-out magazine pages? Look no further! Introducing Hedonistic, the ultimate recipe database app that brings a world of culinary delights right to your fingertips.",
        language: ['JavaScript', 'HTML5', 'CSS3'],
        library: ['Express', 'node.js', 'mongoose'],
        database: ['MongoDb'],
        other: [],
        design: ['figma'],
        ux: [],
        repoLink: "https://github.com/AnastasiiaAsti/hedonistic",
        liveLink: "https://hedonistic-app.herokuapp.com/",
        titleGitHub: "Hedonistic GitHub Link",
        titleLive: "Hedonistic Live Link",
        category: "fullstack",
    },
    {
        id: 3,
        title: "Art Window Website",
        img: [artOne, artTwo,artThree, artFour],
        details: "Immerse yourself in the captivating realm of an art portfolio showcased through a simple yet elegant website. Experience a visual feast as you explore a collection of exquisite artworks, carefully curated to evoke emotions, spark imagination, and transport you to different dimensions.",
        language: ['JavaScript', 'HTML5', 'CSS3'],
        library: ['React', 'bootstrap'],
        other: [],
        design: ['figma'],
        ux: [],
        database:[],
        repoLink: "https://github.com/AnastasiiaAsti/react-site",
        liveLink: "https://github.com/AnastasiiaAsti/react-site",
        titleGitHub: "Art WindowGitHub Link",
        titleLive: "Art Window Live Link",
        category: "frontend",
    },
    {
        id: 4,
        title: "Portfolio V1",
        img: [portfolioOne, portfolioTwo, portfolioThree, portfolioFour],
        details: "First version of a portfolio business website built with help of Django, Python, and Postgresql",
        language: ['Python', 'HTML5', 'CSS3'],
        library: ['Django'],
        other: [],
        design: ['figma'],
        ux: [],
        database: [],
        repoLink: "https://github.com/AnastasiiaAsti/portfolio",
        liveLink: "https://asti-portfolio.herokuapp.com/",
        titleGitHub: "Portfolio GitHub Link",
        titleLive: "Portfolio Live Link",
        category: "fullstack",
    },
    {
        id: 5,
        title: "FemiSpace",
        img: [femiOne,femiTwo,femiThree],
        details: "FemiSpace stands apart as a one-of-a-kind methodology and technology, harnessing the potential of Artificial Intelligence (AI) and Machine Learning (ML). Unlike generic recommendations, our approach revolves around crafting tailor-made programs, taking into account individual client information.The outcome is a daily stream of personalized instructions delivered straight to your mobile device, encompassing everything from enhancing health and preventing diseases to rejuvenating, boosting energy levels, and fortifying stress resistance. Experience the cutting-edge of personalized health empowerment with FemiSpace.",
        language: ['Python', 'HTML5', 'CSS3'],
        library: ['React Native'],
        other: [],
        design: ['figma'],
        ux: [],
        database:["SQLite"],
        repoLink: "https://github.com/AnastasiiaAsti",
        liveLink: "https://femispace.com/",
        titleGitHub: "Art Window GitHub Link",
        titleLive: "Art Window Live Link",
        category: "fullstack",
    },
    {
        id: 6,
        title: "SpaceLAB",
        img: [spaceOne, spaceeTwo, spaceThree, spaceFour],
        details: "SpaceLab is a people-incubator focusing on outer-space tech initiatives. We support and help underrepresented communities gain experience with real-world projects to help diversify the tech space!",
        language: ['JavaScript', 'HTML5', 'CSS3'],
        library: ['React', 'Material UI'],
        other: [],
        design: ['figma'],
        ux: [],
        database:[],
        repoLink: "https://github.com/AnastasiiaAsti/spacelab-react",
        liveLink: "https://www.spacelab.space/",
        titleGitHub: "SpaceLAB GitHub Link",
        titleLive: "SpaceLAB Live Link",
        category: "frontend",
    },
    {
        id: 7,
        title: "DJ ASTI",
        img: [djastiFour, djastiOne, djastiTwo, djastiThree],
        details: "Portfolio website built with ReactJs. Day and night mode, built-in audio player",
        ux: ["Information Architecture", "Agile", "Usability testing", "User Research", "User Flow", "Mockup",],
        design: ['Prototype', 'wireframe', 'figma', 'Accessibility', "responsive design"],
        language: ['JavaScript', 'HTML5', 'CSS3'],
        library: ['React'],
        database: [],
        other: [],
        repoLink: "https://www.djasti.com",
        liveLink: "https://www.djasti.com",
        titleGitHub: "DJ ASTI GitHub Link",
        titleLive: "DJ ASTI Live Link",
        category: "frontend",
    },
    {
        id: 8,
        title: "Shmetflix",
        img: [shmetflixOne, shmetflixTwo],
        details: "Discover a simple yet powerful website that harnesses the capabilities of the OMB (Open Movie Database) API to bring you a vast collection of movie titles at your fingertips. With Shmetflix, finding the perfect movie to watch has never been easier.",
        language: ['JavaScript', 'HTML5', 'CSS3'],
        library: ['React'],
        other: ['OMDb API'],
        design: ['figma'],
        ux: [],
        database: [],
        repoLink: "https://github.com/AnastasiiaAsti/shmetflix",
        liveLink: "https://shmetflix.netlify.app/",
        titleGitHub: "Shmetflix GitHub Link",
        titleLive: "Shmetflix Live Link",
        category: "frontend",
    },
    {
        id: 9,
        title: "E-commerce",
        img: [ecomOne, ecomTwo,ecomThree,ecomFour],
        details: "Enjoy a seamless shopping experience across all devices, including desktops, tablets, and smartphones. ShopEase's responsive design ensures that you can access the website and browse products effortlessly, no matter where you are or what device you're using.",
        language: ['JavaScript', 'HTML5', 'CSS3'],
        library: ['React'],
        other: [],
        design: ['figma'],
        ux: [],
        database: [],
        repoLink: "https://github.com/AnastasiiaAsti/e-commerce",
        liveLink: "https://ecommerce-cstore-clone.netlify.app/",
        titleGitHub: "E-commerceGitHub Link",
        titleLive: "E-commerce Live Link",
        category: "frontend",
    },
    
    {
        id: 10,
        title: "LiveStockCity",
        img: [liveOne, liveTwo, liveThree, liveFour],
        details: "LiveStockCity is a cutting-edge application designed to revolutionize the way livestock trading is conducted. Buyers and sellers in the livestock industry can connect, bid, and trade with ease, all in one convenient platform.",
        language: ['JavaScript', 'HTML5', 'CSS3', 'Python'],
        library: ['Django', 'Bootstrap'],
        other: [],
        design: ['figma'],
        ux: [],
        database: [],
        repoLink: "https://github.com/AnastasiiaAsti",
        liveLink: "https://www.livestockcity.com/",
        titleGitHub: "LiveStockCity GitHub Link",
        titleLive: "LiveStockCity Live Link",
        category: "fullstack",
    },
    {
        id: 11,
        title: "Gwen Schaab Website",
        img: [gwenOne, gwenTwo, gwenThree],
        details: "Discover a simple yet elegant website dedicated to showcasing the talents of a skilled and passionate hair professional. This website serves as a virtual doorway into a world of hair transformations, creativity, and personalized styling expertise. Discover an array of services tailored to meet every client's unique needs and desires. From precision cuts and expert coloring to luxurious hair treatments and special occasion styling, the hair professional's expertise shines through in their comprehensive offerings.",
        language: ['JavaScript', 'HTML5', 'CSS3'],
        library: ['Bootstrap'],
        other: [],
        design: ['figma'],
        ux: [],
        database: [],
        repoLink: "https://github.com/AnastasiiaAsti/gwen-site",
        liveLink: "https://github.com/AnastasiiaAsti/gwen-site",
        titleGitHub: "Gwen Schaab GitHub Link",
        titleLive: "Gwen Schaab Live Link",
        category: "frontend",
    },
    // {
    //     id: 12,
    //     title: "Golobe",
    //     img: [golobeOne, golobeTwo, golobeThree, golobeFour],
    //     details:"Front end for an existing Figma template - Golobe. Golobe is the must-have app for all your travel adventures. Whether you're planning a dream vacation, a business trip, or a spontaneous getaway, Golobe is here to simplify and enhance every aspect of your journey.",
    //     language: ['JavaScript', 'HTML5', 'CSS3'],
    //     library: ['Bootstrap'],
    //     other: [],
    //     design: ['figma'],
    //     ux: [],
    //     database: [],
    //     repoLink: "https://github.com/AnastasiiaAsti/Golobe",
    //     liveLink: "https://github.com/AnastasiiaAsti/Golobe",
    //     titleGitHub: "Golobe GitHub Link",
    //     titleLive: "Golobe Live Link",
    //     category: "frontend",
    // },
    {
        id: 13,
        title: "Organick",
        img: [organickOne, organickTwo, organickThree, organickFour],
        details:"Front end for an existing Figma template - Organick. Discover a wide selection of fresh produce, pantry staples, household essentials, and more, all available at your fingertips. Our app brings together trusted local stores and suppliers, ensuring that you have access to a diverse range of high-quality products.",
        language: ['JavaScript', 'HTML5', 'CSS3'],
        library: [],
        other: [],
        design: ['figma'],
        ux: [],
        database: [],
        repoLink: "https://github.com/AnastasiiaAsti/Organick",
        liveLink: "https://github.com/AnastasiiaAsti/Organick",
        titleGitHub: "Organick GitHub Link",
        titleLive: "Organick Live Link",
        category: "frontend",
    },
    {
        id: 14,
        title: "Not funny, Dad!",
        img: [dadOne],
        details:"Your Infinite Source of Dad Jokes! Get ready to unleash your inner comedian with 'Not funny, Dad!', the ultimate app that delivers an endless supply of dad jokes right to your fingertips. Prepare to laugh, groan, and share the laughter as you dive into a world of corny humor and clever wordplay.",
        language: ['JavaScript', 'HTML5', 'CSS3'],
        library: [],
        other: ['DadJokes API'],
        other: [],
        design: ['figma'],
        ux: [],
        database: [],
        repoLink: "https://anastasiiaasti.github.io/dad-joke-app/",
        liveLink: "https://anastasiiaasti.github.io/dad-joke-app/",
        titleGitHub: "Dad Joke GitHub Link",
        titleLive: "Dad Joke Live Link",
        category: "frontend",
    },
    // {
    //     id: 15,
    //     title: "Podcast",
    //     img: [podcastOne, podcastTwo, podcastThree, podcastFour],
    //     details:"Front end for an existing Figma template - Podcast. Podcast is the ultimate app for podcast enthusiasts, designed to bring you the best audio content from around the globe. Immerse yourself in a world of captivating stories, thought-provoking discussions, and inspiring voices, all conveniently accessible in one powerful platform.",
    //     language: ['JavaScript', 'HTML5', 'CSS3'],
    //     library: [],
    //     other: [],
    //     design: ['figma'],
    //     ux: [],
    //     database: [],
    //     repoLink: "https://github.com/AnastasiiaAsti/Podcast_template",
    //     liveLink: "https://github.com/AnastasiiaAsti/Podcast_template",
    //     titleGitHub: "Podcast GitHub Link",
    //     titleLive: "Podcast Live Link",
    //     category: "frontend",
    // },
    {
        id: 16,
        title: "Memory Game",
        img: [memoryOne, memoryTwo],
        details:"Immerse yourself in a world of colorful cards, captivating graphics, and engaging gameplay. The objective is simple: flip and match pairs of cards to uncover hidden patterns and reveal the treasures within. ",
        language: ['JavaScript', 'HTML5', 'CSS3'],
        library: [],
        other: [],
        design: ['figma'],
        ux: [],
        database: [],
        repoLink: "https://github.com/AnastasiiaAsti/concentration-game",
        liveLink: "https://groovygame.netlify.app/",
        titleGitHub: "Memory Game GitHub Link",
        titleLive: "Memory Game Live Link",
        category: "frontend",
    },
    {
        id: 17,
        title: "Flight App",
        img: [flightOne, flightTwo, flightThree, flightFour ],
        details:"FlightApp is your ultimate flight booking app, designed to make your travel dreams a reality with just a few taps. Whether you're planning a business trip, a family vacation, or a spontaneous getaway, FlightApp provides a seamless and hassle-free experience to find and book your flights.",
        language: ['JavaScript', 'HTML5', 'CSS3'],
        library: ['Express', 'node.js', 'mongoose', 'bootstrap'],
        database: ['MongoDb'],
        other: [],
        design: ['figma'],
        ux: [],
        repoLink: "https://github.com/AnastasiiaAsti/mongoose-flights",
        liveLink: "https://github.com/AnastasiiaAsti/mongoose-flights",
        titleGitHub: "Flight App GitHub Link",
        titleLive: "Flight App Live Link",
        category: "fullstack",
    },
    // {
    //     id: 14,
    //     title: "Goal Digger",
    //     img: [goalDiggerImg],
    //     details:"",
    //     language: ['JavaScript', 'HTML5', 'CSS3'],
    //     library: ['Express', 'node.js', 'mongoose'],
    //     database: ['MongoDb'],
    //     other: ['Mapbox API'],
    //     repoLink: "https://github.com/AnastasiiaAsti/goal-digger",
    //     liveLink: "https://goal-digger-app.herokuapp.com/",
    //     titleGitHub: "Goal Digger GitHub Link",
    //     titleLive: "Goal Digger Live Link",
    //     category: "fullstack",
    // },
    // {
    //     id: 15,
    //     title: "Chalala",
    //     img: [chalalaImg],
    //     details:"Connect with people from around the world who share your interests or have different perspectives. ChatConnect encourages respectful dialogue and open-mindedness, creating an environment where you can learn, share, and grow alongside a vibrant community of individuals who are eager to connect.",
    //     language: ['JavaScript', 'HTML5', 'CSS3', 'Python'],
    //     library: ['Express', 'node.js', 'mongoose', 'tailwind'],
    //     database: ['PostgreSQL'],
    //     repoLink: "https://github.com/AnastasiiaAsti/chalala",
    //     liveLink: "https://chalala-app.herokuapp.com/",
    //     titleGitHub: "Chalala GitHub Link",
    //     titleLive: "Chalala Live Link",
    //     category: "fullstack",
    // },
    {
        id: 20,
        title: "Hired",
        img: [hiredOne, hiredTwo, hiredThree],
        details: "In today's fast-paced and dynamic work environments, staying organized and on top of your tasks is crucial. Hired is the ultimate job tracking application designed to help you efficiently manage your job hunting experience.",
        language: ['JavaScript', 'HTML5', 'CSS3'],
        library: ['Express', 'node.js', 'mongoose'],
        database: ['MongoDb'],
        other: [],
        design: ['figma'],
        ux: [],
        repoLink: "https://github.com/AnastasiiaAsti/hired",
        liveLink: "https://github.com/AnastasiiaAsti/hired",
        titleGitHub: "Hired GitHub Link",
        titleLive: "Hired Live Link",
        category: "frontend",
    },
    {
        id: 21,
        title: "Balance",
        img: [balanceUx, balanceUx2, balanceUx3, balanceUx4],
        details: "As a UX designer for a yoga and meditation Balance app, my mission was to create an intuitive and immersive experience that enables users to find inner peace and balance with ease. I strive to design a visually captivating interface that reflects the serenity and tranquility of the practices, ensuring a seamless flow from one feature to another. By understanding the users' needs and goals, I aim to create an app that guides them through their yoga and meditation journey, providing a sense of clarity, focus, and mindfulness. Through thoughtful interactions, personalized recommendations, and a supportive community, I aim to enhance the user's well-being and create a harmonious digital space for their inner exploration and growth.",
        ux: ["Information Architecture", "Agile", "Usability testing", "User Research", "User Flow", "Mockup",],
        design: ['Prototype', 'wireframe', 'figma', 'Accessibility', "responsive design"],
        language: [],
        library: [],
        database: [],
        other: [],
        repoLink: "https://github.com/AnastasiiaAsti/Landing-Project-RPD",
        liveLink: "none",
        titleGitHub: "RPD GitHub Link",
        titleLive: "RPD Schaab Live Link",
        category: "ux/ui",
    },
    // {
    //     id: 22,
    //     title: "Balance Yoga App",
    //     img: [balanceOne, balanceTwo, balanceThree, balanceFour],
    //     details: "Balance is a comprehensive and user-friendly application that brings the transformative practices of yoga and meditation directly to your fingertips. Whether you're a beginner or an experienced practitioner, Balance offers a wide range of classes, guided meditations, and resources to suit your needs and support you on your journey towards self-discovery.",
    //     language: ['JavaScript', 'HTML5', 'CSS3'],
    //     library: ['Express', 'node.js', 'mongoose'],
    //     database: ['MongoDb'],
    //     other: [],
    //     ux: [],
    //     design: ['figma'],
    //     repoLink: "https://github.com/AnastasiiaAsti/mongoose-yoga",
    //     liveLink: "https://mongoose-yoga.herokuapp.com/",
    //     titleGitHub: "Yoga App GitHub Link",
    //     titleLive: "Yoga App Live Link",
    //     category: "fullstack",
        
    // },

];

export default projects;