import "./ProjectGallery.css";
import projects from "../../data/projects";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function ProjectGallery() {
  const [showAllProjects, setShowAllProjects] = useState(true);
  const [showFrontendProjects, setshowFrontendProjects] = useState(false);
  const [showFullstackProjects, setShowFullstackProjects] = useState(false);
  const [showUxProjects, setShowUxProjects] = useState(false);

  let filteredProjects = [];

  if (showAllProjects) {
    filteredProjects = projects;
  } else if (showFrontendProjects) {
    filteredProjects = projects.filter(
      (project) => project.category === "frontend"
    );
  } else if (showFullstackProjects) {
    filteredProjects = projects.filter(
      (project) => project.category === "fullstack"
    );
  } else if (showUxProjects) {
    filteredProjects = projects.filter(
      (project) => project.category === "ux/ui"
    );
  }

  return (
    <>
      <div className="filters">
        <button
          className="filter-button"
          onClick={() => {
            setShowAllProjects(true);
            setshowFrontendProjects(false);
            setShowFullstackProjects(false);
            setShowUxProjects(false);
          }}
        >
          all projects
        </button>
        <button
          className="filter-button"
          onClick={() => {
            setShowAllProjects(false);
            setshowFrontendProjects(true);
            setShowFullstackProjects(false);
            setShowUxProjects(false);
          }}
        >
          frontend
        </button>
        <button
          className="filter-button"
          onClick={() => {
            setShowAllProjects(false);
            setshowFrontendProjects(false);
            setShowFullstackProjects(true);
            setShowUxProjects(false);
          }}
        >
          fullstack
        </button>
        <button
          className="filter-button"
          onClick={() => {
            setShowAllProjects(false);
            setshowFrontendProjects(false);
            setShowFullstackProjects(false);
            setShowUxProjects(true);
          }}
        >
          ux/ui
        </button>
      </div>
      <div className="gallery">
        {filteredProjects.map((project) => (
          <div className="project-item">
            {project.img.map((image, index) => (
              <Link
                to={project.liveLink}
                className="link_wrapper"
                target="blank"
              >
                <img src={image} key={index} />
              </Link>
            ))}
            <div className="details">
              <h2>{project.title}</h2>
              <p>{project.details}</p>
            </div>
            <div className="tech">
              {project.language.map((l, index) => (
                <span className="skill-project-page language" key={index}>
                  {l}
                </span>
              ))}
              {project.library.map((l, index) => (
                <span
                  className=" skill-project-page library-framework"
                  key={index}
                >
                  {l}
                </span>
              ))}

              {project.database.map((d, index) => (
                <span className="skill-project-page database" key={index}>
                  {d}
                </span>
              ))}
              {project.other.map((o, index) => (
                <span className="skill-project-page other" key={index}>
                  {o}
                </span>
              ))}
              {project.ux.map((u, index) => (
                <span className="skill-project-page ux" key={index}>
                  {u}
                </span>
              ))}
              {project.design.map((d, index) => (
                <span className="skill-project-page design" key={index}>
                  {d}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
