import "./UX.css";
import uxOne from "../../assets/project_screens/balance_ux_1.avif";
import uxTwo from "../../assets/project_screens/balance_ux_2.avif";

export default function Projects() {
  return (
    <div className="ux-container">
      <h3>UX/UI</h3>
      <div className="ux-item">
        <div className="ux-images">
          <img className="desktop" src={uxOne} alt="" />
          <img className="desktop" src={uxTwo} alt="" />
        </div>
        <div className="ux-description">
          <h4>Balance</h4>
          <p>
            As a UX designer for a yoga and meditation Balance app, my mission
            was to create an intuitive and immersive experience that enables
            users to find inner peace and balance with ease. I strive to design
            a visually captivating interface that reflects the serenity and
            tranquility of the practices, ensuring a seamless flow from one
            feature to another. By understanding the users' needs and goals, I
            aim to create an app that guides them through their yoga and
            meditation journey, providing a sense of clarity, focus, and
            mindfulness. Through thoughtful interactions, personalized
            recommendations, and a supportive community, I aim to enhance the
            user's well-being and create a harmonious digital space for their
            inner exploration and growth.
          </p>
          <div className="skills">
            <div className="skill design">
              <p>Wireframe</p>
            </div>
            <div className="skill design">
              <p>Prototype</p>
            </div>
            <div className="skill ux">
              <p>Mockup</p>
            </div>
            <div className="skill ux">
              <p>User Flow</p>
            </div>
            <div className="skill design">
              <p>Figma</p>
            </div>
            <div className="skill ux">
              <p>User Research</p>
            </div>
            <div className="skill ux">
              <p>Usability testing</p>
            </div>
            <div className="skill ux">
              <p>Agile</p>
            </div>
            <div className="skill other">
              <p>Application development</p>
            </div>
            <div className="skill ux">
              <p>Information Architecture</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
